import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// module imports
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/shared/angular.material.module';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { StarRatingModule } from 'angular-star-rating';

// quicklinks
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';

// Application Components
import { SharedSidebarComponentsModule } from '@app/shared/components/sidebar.module';
import { DocumentComponentsModule } from '../document/document.module';
import { ProgramComponentsModule } from '../program/program.module';
import { ApplicationProgramComponentsModule } from './application.program.module';
import { ApplicationAcceptedComponent } from './components/application-accepted/application-accepted.component';
import { ApplicationAdvisorListComponent } from './components/application-advisor-list/application-advisor-list.component';
import { ApplicationBookNowComponent } from './components/application-book-now/application-book-now.component';
import { ApplicationBookingBlockComponent } from './components/application-booking-block/application-booking-block.component';
import { ApplicationCancelComponent } from './components/application-cancel/application-cancel.component';
import { ApplicationCancelledComponent } from './components/application-cancelled/application-cancelled.component';
import { ApplicationCompleteComponent } from './components/application-complete/application-complete.component';
// eslint-disable-next-line max-len
import { ApplicationEnrolledChecklistComponent } from './components/application-enrolled-checklist/application-enrolled-checklist.component';
import { ApplicationEnrolledComponent } from './components/application-enrolled/application-enrolled.component';
// eslint-disable-next-line max-len
import { ApplicationHousingSuggestionsComponent } from './components/application-housing-suggestions/application-housing-suggestions.component';
import { ApplicationHousingComponent } from './components/application-housing/application-housing.component';
import { ApplicationMissingComponent } from './components/application-missing/application-missing.component';
import { ApplicationPassportComponent } from './components/application-passport/application-passport.component';
import { ApplicationPaymentActionComponent } from './components/application-payment-action/application-payment-action.component';
import { ApplicationPendingComponent } from './components/application-pending/application-pending.component';
import { ApplicationPreChecklistEnrollmentComponent } from './components/application-pre-enrolled-checklist/checklist-enrollment.component';
// eslint-disable-next-line max-len
import { ApplicationQuickActionsMobileComponent } from './components/application-quick-actions-mobile/application-quick-actions-mobile.component';
import { ApplicationQuickActionsComponent } from './components/application-quick-actions/application-quick-actions.component';
import { ApplicationReservedComponent } from './components/application-reserved/application-reserved.component';
import { ApplicationReviewComponent } from './components/application-review/application-review.component';
@NgModule({
    declarations: [
        ApplicationAcceptedComponent,
        ApplicationAdvisorListComponent,
        ApplicationBookNowComponent,
        ApplicationBookingBlockComponent,
        ApplicationCancelComponent,
        ApplicationCancelledComponent,
        ApplicationCompleteComponent,
        ApplicationEnrolledChecklistComponent,
        ApplicationEnrolledComponent,
        ApplicationHousingComponent,
        ApplicationHousingSuggestionsComponent,
        ApplicationMissingComponent,
        ApplicationPassportComponent,
        ApplicationPaymentActionComponent,
        ApplicationPendingComponent,
        ApplicationPreChecklistEnrollmentComponent,
        ApplicationQuickActionsComponent,
        ApplicationQuickActionsMobileComponent,
        ApplicationReservedComponent,
        ApplicationReviewComponent,
    ],
    imports: [
        QuicklinkModule,
        IonicModule,
        CommonModule,
        FormsModule,
        DocumentComponentsModule,
        SharedComponentsModule,
        ProgramComponentsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        RouterModule,
        PipesComponentsModule,
        MatSnackBarModule,
        FontAwesomeModule,
        SharedSidebarComponentsModule,
        ApplicationProgramComponentsModule,
        StarRatingModule.forRoot(),
    ],
    exports: [
        QuicklinkModule,
        ApplicationAcceptedComponent,
        ApplicationAdvisorListComponent,
        ApplicationBookNowComponent,
        ApplicationBookingBlockComponent,
        ApplicationCancelComponent,
        ApplicationCancelledComponent,
        ApplicationCompleteComponent,
        ApplicationEnrolledChecklistComponent,
        ApplicationEnrolledComponent,
        ApplicationHousingComponent,
        ApplicationHousingSuggestionsComponent,
        ApplicationMissingComponent,
        ApplicationPassportComponent,
        ApplicationPaymentActionComponent,
        ApplicationPendingComponent,
        ApplicationPreChecklistEnrollmentComponent,
        ApplicationQuickActionsComponent,
        ApplicationQuickActionsMobileComponent,
        ApplicationReservedComponent,
        ApplicationReviewComponent,
    ],
})
export class ApplicationComponentsModule {}
