import { getMonth } from 'date-fns';

export const canBook = (status: any): boolean => {
    if (status.application.program.lastminute) {
        const currentMonth = new Date().getMonth();
        const appMonth = getMonth(new Date(status.application.startdate));
        const calcMonth = appMonth - currentMonth;
        // check if applying for the same month, the month after today's month and Dec to Jan apps
        if (calcMonth === 0 || calcMonth === 1 || calcMonth === 11) {
            // check if the apps are on the same year
            if (new Date().getFullYear() === status.application.startyear) {
                return false; // one month apart and on the same year; can't book
            } else {
                return true; // if the application is in same month but different year; can book
            }
        } else {
            return true; // if the application months are more than month apart; can book
        }
    } else {
        return true; // program is not lastminute
    }
};
